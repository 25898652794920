.datepicker {
  height: 38px;
  padding-left: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group-date {
  display: flex;
  width: 100%;
  background-color: #fff;
}
.datepicker--container {
  position: relative;
  max-width: 420px;
  width: 100%;
}
.date--placeholder {
  border-color: rgba(206, 212, 218, 0.5) !important;
}
.custom--datepicker--select {
  color: rgba(0, 0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0) !important;
  -moz-user-select: none;
  user-select: none;
  z-index: 10;
  li {
    cursor: pointer;
    min-width: 220px;
    width: calc(100% + 26px);
    background-color: #fff;
    color: #68605a;
    height: 30px;
    border: 1px solid #e0e0e0;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    padding: 3px 8px;
    margin: 0;
    margin-left: -1px;
    &:first-child {
      margin-top: 28px;
    }
    &:last-child {
      border: 1px solid #e0e0e0;
      border-top: 1px solid #fff;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &:hover {
      background-color: #dd0030;
      color: #fff;
      .li--icon {
        color: #68605a;
      }
    }
    .datepicker--option--container {
      display: flex;
      align-items: center;
      .li--icon {
        width: 35px;
        padding: 4px 0;
        background-color: #e0e0e0;
        border-radius: 3px;
        font-size: 14px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          height: max-content;
          margin-left: -12px;
        }
      }
    }
  }
}
.datepicker--span {
  cursor: default;
  position: absolute;
  font-size: 1rem;
  padding: 11px 24px 6px 5px;
  width: 100%;
  display: flex;
  &-visible {
    z-index: 100;
  }
  .datepicker--icon {
    padding: 4px;
    width: 35px;
    background-color: #e0e0e0;
    border-radius: 3px;
    margin: -4px 4px 0 4px;
    font-size: 14px;
    height: 24px;
    text-align: center;
  }
  .span--placeholder {
    padding-left: 5px;
    opacity: 0.5;
  }
  input {
    font-stretch: condensed;
    margin-top: -5px;
    color: #68605a;
    max-width: 420px;
    text-overflow: ellipsis;
    width: 100%;
  }
  input.invalid {
    color: #dd0030;
  }
}
.datepicker--calendar--identifier {
  position: absolute !important;
  background-color: #fff;
  z-index: 10;
}

.date-picker__container {
  position: relative;
  max-width: 420px;
  width: 100%;
  height: 38px;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  .date-picker__dropdown-panel {
    position: absolute;
    padding: 10px;
    top: 40px;
    border: 1px solid rgba(black, 0.1);
    background-color: #fff;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 10;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  .form-group {
    height: 100%;
  }
  .date-picker__input {
    cursor: default;
    font-size: 1rem;
    padding: 6px 24px 6px 10px;
    width: 100%;
    display: flex;
    .date-picker__icon {
      position: absolute;
      top: 5px;
      right: 3px;
      padding: 5px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      font-size: 14px;
      height: 24px;
      cursor: default;
      &:hover {
        background-color: rgba(#e0e0e0, 0.5);
      }
    }
    input {
      font-stretch: condensed;
      color: #68605a;
      max-width: 420px;
      width: 100%;
    }
  }
}
