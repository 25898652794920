@import '../../../assets/scss/variables';

.header-tool-labeled-value {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  &__label {
    font-size: 12px;
    color: $gray-color-secondary;
  }

  &__value {
    color: $gray-color-body;
  }
}
