@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;700&display=swap');
@import "~bootstrap/scss/bootstrap.scss";
@import "./reset.scss";
@import "./variables.scss";
@import "./custom.scss";
@import './mixins.scss';
@import './helpers.scss';

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
  color: $gray-color-body;
  background-color: #ededf2;
  overflow: hidden;
}

.page-content {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background-color: white;
  &__body {
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    max-height: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
  }
}

.account-page-container {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: hidden;
}

.page-container {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.page-header-container {
  padding: 24px 24px 0 24px;
}

.page-main-content_with-side-item {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;

  .page-main-content {
    flex: 1 1 auto;
  }
}

.page-main-content {
  height: 100%;
  padding: 0px 24px 24px 24px;
  overflow-y: auto;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__tools {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 8px;
  }
}

.page-title {
  font-size: 32px;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 800;
  line-height: 44px;
  height: 44px;
  &__user-settings {
    margin-bottom: 20px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.page-toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .left-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
  }

  .right-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
  }
}

[class*='col-'] {
  padding-right: 10px;
  padding-left: 10px;
}

.text {
  &-red {
    color: $red;
  }
  &-disabled {
    color: #a6a6a6;
  }
  &-in-row {
    white-space: nowrap;
  }
}

.disabled-text {
  pointer-events: none;
  &:hover,
  &:active {
    color: inherit;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #a5a5a5;
}

.google-visualization-tooltip {
  pointer-events: none;
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

.empty__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  height: 100%;
  min-height: 80px;
  svg {
    width: 24px;
    height: 24px;
    color: $info-color-1;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: $gray-color-2;
  }
}

.red-button {
  color: #fff;
  background-color: $accent-color-1;
  border-color: $accent-color-1;
  user-select: none;
  text-align: center;
  width: max-content;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  border-radius: 18px;
  padding: 11px 20px;
  &:hover {
    background-color: $accent-color-2;
  }
  &:active {
    background-color: $accent-color-3;
  }
  &:disabled {
    opacity: 0.5;
    &:hover,
    &:active {
      background-color: $accent-color-1;
    }
  }
}

@media (max-width: $xs) {
  html,
  body,
  #root {
    height: 100%;
  }
  .page-header-container {
    padding: 24px 20px 0;
  }
  .page-content {
    width: 100%;
    height: 100%;
  }
  .page-header {
    gap: 16px 5px;
    position: static;
    flex-wrap: wrap;
    &__container {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      position: relative;
      justify-content: space-between;
      height: max-content;
      &:has(> .page-header__tools > .mobile-page-search > :not(.closed)) {
        margin-bottom: 41px;
      }
    }
    &__tools {
      align-items: flex-start;
    }
  }
  .page-title {
    height: max-content;
    min-height: 44px;
    font-size: 28px;
    &.word-break {
      word-wrap: break-word;
      word-break: break-all;
    }
  }
  .page-toolbar {
    display: none;
    &.hidden {
      display: none;
    }
    &__visible {
      display: block;
    }
  }
}

@import './table/index.scss';
