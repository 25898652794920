@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400');

// Global values
// --------------------------------------------------
$base-color:             #68605a;
$base-background-color:  #ededf2;
$base-font-family:       'Roboto', 'Helvetica Neue', Helvetica, SegoeUI, Arial, sans-serif;
$second-font-family:     'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$second-background-color: #fff;
$hover-background-color:  #f6f6f8;

$shadow-main-sidebar: 7px 4px 14px 0 rgba(black, 0.08);
$shadow-modal: 0 1px 4px 0 rgba(black, 0.12);
$shadow-side-modal: -10px 1px 35px 0 rgba(black, 0.12);
$shadow-context-menu: 0 4px 9px 0 rgba(black, 0.1);
$shadow-dropdown-menu: 0px 6px 20px 0px #0000001a;
$shadow-button: 0px 6px 20px 0px #0000001a;
$shadow-radio-input: 0px 1px 3px 0px rgba(black, 0.4);
$shadow-notification: 0px 5px 20px 0px rgba(0, 0, 0, 0.09);

$border-radius-primary: 18px;
$border-radius-secondary: 14px;
$border-radius-dropdown-list: 4px;

// Colors
// --------------------------------------------------

$accent-color-1: #dd0031;
$accent-color-2: #f51f4e;
$accent-color-3: #b50028;
$accent-color-4: #f5b3c1;
$accent-color-5: #f8ccd6;
$accent-color-6: #dd003133; // 20%
$accent-color-7: #dd00311a; // 10%
$accent-color-8: #dd003108; // 3%
$accent-color-9: #dd003085; // 50%
$accent-color-10: #dd00314d; // 30%

$danger-color-1: #cb444a;
$danger-color-2: #df555b;
$danger-color-3: #a2363b;
$danger-color-4: #f3d8da;
$danger-color-5: #cb444a1a; // 10%
$danger-color-6: #cb444a05; // 5%

$warning-color-1: #dbb91d;
$warning-color-2: #f4d548;
$warning-color-3: #c8a507;
$warning-color-4: #fcf0b8;
$warning-color-5: #f4d5481a; // 10%
$warning-color-6: #f4d54805; // 5%

$success-color-1: #17b61f;
$success-color-2: #1fcc28;
$success-color-3: #009308;
$success-color-4: #d1f0d2;
$success-color-5: #17b61f1a; // 10%
$success-color-6: #17b61f05; // 5%

$info-color-1: #d872dd;
$info-color-2: #f78fff;
$info-color-3: #b24bb7;
$info-color-4: #f4c1f6;
$info-color-5: #d872dd1a; //10%
$info-color-6: #d872dd05; //5%

$gray-color-body: #1a1a1a;
$gray-color-secondary: #808080;
$gray-color-tertiary: #a6a6a6;
$gray-color-1: #333333;
$gray-color-2: #595959;
$gray-color-3: #cccccc;
$gray-color-4: #dedede;
$gray-color-5: #ebebeb;
$gray-color-6: #f2f2f2;

$border-gray-color: #e3e3e3;
$disabled-gray-color: #aeb9bf;

$player-title-background: #464646CC; // 80%

$lightgreen: #AFD135;
$green: #009408;
$yellow: #fff200;
$orange: #FFA100;
$orange-2: #FF7D00;
$greyBG: #fbfbfb;

$color-pink: #dd0030;
$color-red: #f66;
$color-purple: #852b99;
$color-fuchsia: #ff00ff;
$color-green: #79a640;
$color-lime: #00ff00;
$color-navy: #1b2633;
$color-blue: #3399ff;
$color-teal: #008080;
$color-aqua: #00ffff;
$color-yellow: #ffc800;
$color-orange: #ea7200;
$color-silver: #ddd;
$color-white: #fff;
$color-black: #000;
$color-gray: #999999;
$color-error: #c93d44;
$color-inactive: #afaba7;
$color-transparent: #00000000;

$status-color-red: #dd0031;
$status-color-rubin: #cb444a;
$status-color-yellow: #dbb91d;
$status-color-green: #17b61f;
$status-color-pink: #d872dd;
$status-color-gray: #a6a6a6;

// Forms
// --------------------------------------------------
$form-border-color:      #dbdbdb;
$table-border-color:     #e6e6ed;

$red: #dd0031;
$pink: rgb(243, 161, 161);
$red-dark: #8d001f;
$form-grey: #495057;
$form-white: #ffffff;
$border-grey: #ced4da;
$invalid-red: #dc3545;
$disabled-red: #e98097;

$lg: 1200px;
$md: 1044px;
$sm: 767px;
$xs: 575px;
$xxs: 320px;
