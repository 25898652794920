.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-28 {
  margin-bottom: 28px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-auto {
  margin-left: auto;
}
.mr-6 {
  margin-right: 6px;
}
.mr-8 {
  margin-right: 8px;
}

.pt-24 {
  padding-top: 24px;
}

.pb-04 {
  padding-bottom: 4px;
}
.pb-24 {
  padding-bottom: 24px;
}

.gap-12 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.gap-20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
