@import '../../../assets/scss/variables.scss';

.custom-cell__tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  .tag-item {
    background-color: $gray-color-6;
    font-size: 14px;
    width: max-content;
    max-width: 100%;
    border-radius: 4px;
    text-overflow: ellipsis;
    height: 22px;
    position: relative;
    padding: 3px 8px;
    }
}
