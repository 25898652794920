.status-tag {
  height: 22px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: 1;
  border-radius: 8px;
}
