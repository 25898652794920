.btn-primary {
  color: #fff;
  background-color: $color-pink;
  border-color: $color-pink;

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.disabled, &:disabled {
    color: #fff;
    background-color: darken($color-pink, 10%);
    border-color: darken($color-pink, 10%);
  }

  &:focus, &.focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem lighten($color-pink, 45%);
  }

  &.disabled, &:disabled {
    opacity: 0.5;
  }
}

.rnc__base {
  overflow-y: auto;
  width: calc(100% - 10px);
  right: 0;
  top: 0;
  scrollbar-color: $gray-color-2;
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $gray-color-2;
  }
  .rnc__notification-container--center {
    display: none;
  }
  .rnc__notification-container--top-right {
    min-width: 315px !important;
    overflow-x: visible;
    right: 0;
    .rnc__notification {
      padding: 0 10px 0 20px;
      width: 345px;
      &-item {
        min-height: 48px;
        border-radius: 24px;
        background-color: $color-white !important;
        color: $gray-color-body;
        border-radius: 24px;
        min-height: 48px;
        box-shadow: $shadow-notification;
        opacity: 1;
        .rnc__notification-content {
          display: flex;
          align-items: center;
          padding: 8px 8px 8px 36px;
          .rnc__notification-timer {
            width: 0;
          }
          .rnc__notification-close-mark {
            background-color: $color-white;
            cursor: pointer;
            width: 15px;
            height: 15px;
            fill: $gray-color-secondary;
            background: url('../../assets/img/icons/notifications/Close.svg');
            background-position: center;
            background-repeat: no-repeat;
            top: auto;
            right: 15px;
            &::after {
              content: none;
            }
          }
        }
        &--success,
        &--danger,
        &--info,
        &--warning {
          border-left: none;
          position: relative;
          &::before {
            content: '';
            width: 18px;
            height: 18px;
            position: absolute;
            left: 12px;
            top: 50%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateY(-50%);
          }
        }
        &--success {
          .rnc__notification-message {
            color: $success-color-1;
          }
          &::before {
            background-image: url('../../assets/img/icons/notifications/Success.svg');
          }
        }
        &--info {
          .rnc__notification-message {
            color: $info-color-1;
          }
          &::before {
            background-image: url('../../assets/img/icons/notifications/Info.svg');
          }
        }
        &--warning {
          .rnc__notification-message {
            color: $warning-color-1;
          }
          &::before {
            background-image: url('../../assets/img/icons/notifications/Warning.svg');
          }
        }
        &--danger {
          .rnc__notification-message {
            color: $danger-color-1;
          }
          &::before {
            background-image: url('../../assets/img/icons/notifications/Error.svg');
          }
        }
      }
      &-message {
        font-weight: 600;
        line-height: 17.5px;
        max-width: calc(100% - 30px);
        max-height: 100px;
        text-overflow: ellipsis;
        overflow: auto;
      }
    }
  }
}


