@import '../../assets/scss/variables';

.custom-time-picker {
  .react-time-input-picker-wrapper {
    display: flex;
    align-items: center;
    border: none;
    .react-time-input-picker {
      margin: 0;
    }
    .inputWrapper {
      margin: 0 5px;
      & > input {
        margin: 0;
        width: 70px;
        height: 44px;
        border-radius: $border-radius-primary;
        background-color: $gray-color-6;
        font-size: 16px;
        &:focus {
          background-color: $gray-color-4;
        }
      }
      &:nth-child(1)::after {
        margin-left: 10px;
      }
    }
  }
}

.custom-time-label {
    font-size: 32px;
}
