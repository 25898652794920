@import '../../assets/scss/variables';

.code-preview {
  &__container {
    max-height: 100%;
    position: relative;
    display: flex;
    border: 1px solid $border-gray-color;
    border-radius: $border-radius-primary;
    color: $color-black;
    overflow: hidden;
  }

  flex-grow: 1;
  padding: 28px;
  font-size: 16px;
  overflow: auto;

  &__copy-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
