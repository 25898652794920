@import '../../../assets/scss/variables';

.main-large-button {
  padding: 0 24px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-primary;
  background-color: $accent-color-1;
  color: $color-white;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;

  &:hover {
    background-color: $accent-color-2;
  }
  &:active {
    background-color: $accent-color-3;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

