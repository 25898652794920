@import "../../../assets/scss/variables";

.header-tool-button {
  padding: 14px 24px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  background-color: $accent-color-1;
  border-radius: $border-radius-primary;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: $color-white;

  &:hover {
    background-color: $accent-color-2;
  }

  &:active {
    background-color: $accent-color-3;
  }

  &:disabled {
    opacity: 0.5;
    &:hover,
    &:active {
      background-color: $accent-color-1;
    }
  }
}

@media (max-width: $xs) {
  .header-tool-button {
    width: 100%;
    justify-content: center;
  }
}
